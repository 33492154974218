.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: large;
  /* position: fixed; */
  /* left: 0;
  bottom: 0rem; */
  height: 3rem;
  width: 100vw;
  background-color: var(--background-secondary);
  border-width: 1px 0px 0px 0px;
  border-color: var(--text-secondary);
  border-style: solid;
  z-index: 10;
}

.footLinks {
  font-style: italic;
  font-size: 1em;
  color: var(--text-primary);
  text-decoration: none;
  position: relative;
}

.footer .active {
  color: var(--text-secondary);
}

.imprintLink {
  color: var(--text-primary);
  text-decoration: none;
  position: relative;
  width: fit-content;
  height: fit-content;

  align-self: center;
}
